import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavigationComponent } from './navigation/navigation.component';
import { HomeComponent } from './home/home.component';
import { BroodjesComponent } from './broodjes/broodjes.component';
import { SchotelsComponent } from './schotels/schotels.component';
import { FotobroodjeComponent } from './fotobroodje/fotobroodje.component';
import { AboutComponent } from './about/about.component';
import { FotoschotelsComponent } from './fotoschotels/fotoschotels.component';
import { FotosoepComponent } from './fotosoep/fotosoep.component';
import { SoepComponent } from './soep/soep.component';
import { ContactComponent } from './contact/contact.component';
import { BestelComponent } from './bestel/bestel.component';

@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    HomeComponent,
    BroodjesComponent,
    SchotelsComponent,
    FotobroodjeComponent,
    AboutComponent,
    FotoschotelsComponent,
    FotosoepComponent,
    SoepComponent,
    ContactComponent,
    BestelComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
