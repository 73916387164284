import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bestel',
  templateUrl: './bestel.component.html',
  styleUrls: ['./bestel.component.scss']
})
export class BestelComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
