<div class="schotels" id="schotels">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xl-2"></div>
            <div class="col-3" style="width: 40rem;">
                <div class="schotels__title">Koude schotels</div>
            </div>
            <div class="col-6 schotels__soort-box" style="width: 55rem;">
                <div class=schotels__soort>Aardappelsla, eiersla, pastasla, komkommersla, seldersla</div>
                <div class=schotels__soort>Beleg naar keuze</div>
                <div class=schotels__soort>Klein broodje</div>
                <div class=schotels__soort>Mayonaise en/of vinaigrette</div>
            </div>
            <div class="col-lg-1"></div>
        </div>
        <div style="height: 10rem;"></div>
    </div> 
</div>