<div class="bestel" id="bestel">
    <div class="container-fluid">
        <div class="bestel__title">Bestellen</div>
        <div class="bestel__text-1">
            U kan bij ons ook broodjes op voorhand bestellen.
        </div>
        <div class="bestel__text-2">
            - Max 5 broodjes te bestellen voor 11u.
        </div>
        <div class="bestel__text-3">
            - Meer dan 5 broodjes een dag op voorhand te bestellen.
        </div>
        <div class="bestel__text-4">
            U kan uw bestelling doorgeven via Whatsapp op het nr 0496 22 12 87.
        </div>
        <a class="bestel__link-nl" href="assets/files/Prijslijst Klanten 20230501 Nederlands.pdf" target="_blank"
            title="Download Prijslijst NL">
            <span class="bestel__link-nl-span">
                <i class="fas fa-file-pdf fa-3x"></i>
            </span>
            <span class="bestel__link-nl-text">
                Download prijslijst Nederlands
            </span>
        </a>
        <a class="bestel__link-en" href="assets/files/Prijslijst Klanten 20230501 Engels.pdf" target="_blank"
            title="Download Prijslijst EN">
            <span class="bestel__link-en-span">
                <i class="fas fa-file-pdf fa-3x"></i>
            </span>
            <span class="bestel__link-en-text">
                Download prijslijst English
            </span>
        </a>
        <a class="bestel__link-fr" href="assets/files/Prijslijst Klanten 20230501 Frans.pdf" target="_blank"
            title="Download Prijslijst FR">
            <span class="bestel__link-fr-span">
                <i class="fas fa-file-pdf fa-3x"></i>
            </span>
            <span class="bestel__link-fr-text">
                Download prijslijst Frans
            </span>
        </a>

    </div>
</div>