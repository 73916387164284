<div class="home">
    <div class="container">
        <div class="home__title">
            Broeikeswinkel
        </div>
        <div class="home__text">
            De beste broeikes van 't stad !
        </div>
    </div>
</div>

