<div class="about" id="about">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xl-1"></div>
            <div class="col-lg-6">
                <div class="about__text-1">
                    Reeds 25 jaar draagt ’t Winkeltje versheid hoog in het vaandel !<br>
                    Elke dag échte verse broodjes van de warme bakker…<br>
                    Huisgemaakte slaatjes…<br>
                    Verse groenten…<br>
                    Dit alles met liefde voor het vak , steeds met de glimlach en een vriendelijk woord.<br>
                    Eenvoud en versheid siert !
                </div>
            </div>
            <div class="col-xl-5"></div>

        </div>
        <div class="about__banner">
            <!-- <div class="about__banner-pic1"></div> -->
            <img src="assets/img/Bestelling.jpg" alt="Bestelling" class="about__banner-pic about__banner-pic1">
            <img src="assets/img/Bord.jpg" alt="Stoepbord" class="about__banner-pic about__banner-pic2">
            <img src="assets/img/Mand.jpg" alt="Broodjes" class="about__banner-pic about__banner-pic3">
            <img src="assets/img/Veggyprepare.jpg" alt="Veggy" class="about__banner-pic about__banner-pic4">
            <img src="assets/img/Bagnat.jpg" alt="Veggy" class="about__banner-pic about__banner-pic5">
            <img src="assets/img/Smoske.jpg" alt="Veggy" class="about__banner-pic about__banner-pic6">
            <img src="assets/img/Broodjes.jpeg" alt="Veggy" class="about__banner-pic about__banner-pic7">
        </div>

    </div>
</div>