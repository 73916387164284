<div class="contact" id="contact">
    <div class="container-fluid">
        <!-- <div style="height: 5rem;"></div> -->
        <div class="contact__title">
            Contact
        </div>
        <div class="contact__box">
            <div class="contact__box-adres">
                <i class="fas fa-map-marker-alt"></i>
                &nbsp; 
                <a class="contact__box-link-email" target="_blank" href="https://www.google.com/maps/place/Lange+Klarenstraat+27,+2000+Antwerpen/@51.2185526,4.4059661,17z/data=!3m1!4b1!4m5!3m4!1s0x47c3f6f9123fe1ab:0xddf20fc090419e76!8m2!3d51.2185526!4d4.4081548">
                    Lange Klarenstraat 27, 2000 Antwerpen</a>
            </div>

            <div class="contact__box-open">
                <i class="fas fa-clock"></i>
                <!-- <i class="far fa-clock"></i> -->
                &nbsp;Van maandag tot vrijdag van 7u tot 15u. Zaterdag, zon- en feestdagen gesloten.
            </div>
            <div class="contact__box-email">
                <i class="fas fa-envelope"></i>
                <!-- <i class="far fa-envelope"></i> -->
                <!-- &nbsp;info@broeikeswinkel.be -->
                <a class="contact__box-link-email" href="mailto:info@broeikeswinkel.be">&nbsp; info@broeikeswinkel.be</a>
            </div>
            <div class="contact__box-fb">
                <a class="contact__box-link-social" 
                    href="https://www.facebook.com/BroodjeszaaktWinkeltje/" target="_blank"><i class="fab fa-facebook-square fa-2x"></i></a>
            </div>
            <div class="contact__box-insta">
                <a class="contact__box-link-social" 
                    href="https://www.instagram.com/t_winkeltje_broodjes/" target="_blank"><i class="fab fa-instagram fa-2x"></i></a>
            </div>
        </div>

    </div>
</div>