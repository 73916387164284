<div class="broodjes" id="broodjes">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xl-2"></div>
            <div class="col-3" style="width: 40rem;">
                <div class="broodjes__title">Broodsoorten</div>
            </div>
            <div class="col-6 broodjes__beleg-box" style="width: 55rem;">
                <div class=broodjes__beleg>Kleine witte en licht grijze broodjes</div>
                <div class=broodjes__beleg>Ronde witte, grijze en maya bagnats</div>
                <div class=broodjes__beleg>Halve stokbroden wit en grijs</div>
            </div>
            <div class="col-lg-1"></div>
        </div>
        <div class="row">
            <div class="broodjes__line"></div>
        </div>
        <div class="row">
            <div class="col-xl-2"></div>
            <div class="col-3" style="width: 40rem;">
                <div class="broodjes__title">Huisbereide slaatjes</div>
            </div>
            <div class="col-6 broodjes__beleg-box" style="width: 55rem;">
                <div *ngFor="let sl of selfMadeList;let i = index;">
                    <span class=broodjes__beleg>{{sl.beleg}}</span>
                </div>
            </div>
            <div class="col-lg-1"></div>
        </div>
        <div class="row">
            <div class="broodjes__line"></div>
        </div>
        <div class="row">
            <div class="col-xl-2"></div>
            <div class="col-3" style="width: 40rem;">
                <div class="broodjes__title">Kaas</div>
            </div>
            <div class="col-6 broodjes__beleg-box" style="width: 55rem;">
                <div *ngFor="let kl of kaasList;let i = index;">
                    <div class=broodjes__beleg>{{kl.beleg}}</div>
                </div>
            </div>
            <div class="col-lg-1"></div>
        </div>

        <div class="row">
            <div class="broodjes__line"></div>
        </div>
        <div class="row">
            <div class="col-xl-2"></div>
            <div class="col-3" style="width: 40rem;">
                <div class="broodjes__title">Veggy</div>
            </div>
            <div class="col-6 broodjes__beleg-box" style="width: 55rem;">
                <div *ngFor="let vl of veggyList;let i = index;">
                    <div class=broodjes__beleg>{{vl.beleg}}</div>
                </div>
            </div>
            <div class="col-lg-1"></div>
        </div>

        <div class="row">
            <div class="broodjes__line"></div>
        </div>
        <div class="row">
            <div class="col-xl-2"></div>
            <div class="col-3" style="width: 40rem;">
                <div class="broodjes__title">Andere</div>
            </div>
            <div class="col-6 broodjes__beleg-box" style="width: 55rem;">
                <div *ngFor="let al of andereList;let i = index;">
                    <div class=broodjes__beleg>{{al.beleg}}</div>
                </div>
            </div>
            <div class="col-lg-1"></div>
        </div>
    </div>