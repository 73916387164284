<div class="soep" id="soep">
    <div class="container-fluid">
        <div class="soep__box">
            <div class="soep__box-1">
                <div class="soep__title">Soep</div>
            </div>
            <div class="soep__box-2">
                <div class="soep__title-2">
                    Tijdens de winterperiode,<br>
                    van oktober tem april,<br>
                    alle dagen verse huisgemaakte soep
                </div>
            </div>
            <div class="soep__box-3">
                <!-- <img class="soep__pic-3" src="assets/img/Soup.png" alt="Soep"> -->
            </div>
            <div class="soep__box-4">
                <!-- <img class="soep__pic-4" src="assets/img/Soup2.png" alt="Soep"> -->
            </div>
            <div class="soep__box-5">
                <div class="row">
                    <div class="col-md-3 soep__text-dag">Maandag</div>
                    <div class="col-md-9 soep__text-soep">Tomaten groentensoep</div>
                </div>
                <div class="row">
                    <div class="col-md-3 soep__text-dag">Dinsdag</div>
                    <div class="col-md-9 soep__text-soep">Dagsoep</div>
                </div>
                <div class="row">
                    <div class="col-md-3 soep__text-dag">Woensdag</div>
                    <div class="col-md-9 soep__text-soep">Dagsoep</div>
                </div>
                <div class="row">
                    <div class="col-md-3 soep__text-dag">Donderdag</div>
                    <div class="col-md-9 soep__text-soep">Dagsoep</div>
                </div>
                <div class="row">
                    <div class="col-md-3 soep__text-dag">Vrijdag</div>
                    <div class="col-md-9 soep__text-soep">Tomatensoep gemixt</div>
                </div>

            </div>
            <div class="soep__box-6"></div>
            <div class="soep__box-7"></div>
            <div class="soep__box-8">
                <!-- <img class="soep__pic-8" src="assets/img/Soup3.png" alt="Soep"> -->

            </div>
            <div class="soep__box-9"></div>
        </div>
    </div>

    <!-- <div class="row">
            <div class="col-sm-2"></div>
            <div class="col-md-2">
                <div class="soep__title">Soep</div>
            </div>
            <div class="col-md-7 soep__text-box">
                <div class=soep__text-1>Alle dagen - tijdens de winterperiode - verse huisgemaakte soep</div>
                <div class="row">
                    <div class="col-sm-2"></div>
                    <div class="col-xl-2 soep__text-dag">Maandag</div>
                    <div class="col-xllg soep__text-soep">Tomaten groentensoep</div>
                </div>
                <div class="row">
                    <div class="col-xl-2"></div>
                    <div class="col-xl-2 soep__text-dag">Dinsdag</div>
                    <div class="col-lg-8 soep__text-soep">Dagsoep</div>
                </div>
                <div class="row">
                    <div class="col-xl-2"></div>
                    <div class="col-xl-2 soep__text-dag">Woensdag</div>
                    <div class="col-lg-8 soep__text-soep">Dagsoep</div>
                </div>
                <div class="row">
                    <div class="col-xl-2"></div>
                    <div class="col-xl-2 soep__text-dag">Donderdag</div>
                    <div class="col-lg-8 soep__text-soep">Dagsoep</div>
                </div>
                <div class="row">
                    <div class="col-xl-2"></div>
                    <div class="col-xl-2 soep__text-dag">Vrijdag</div>
                    <div class="col-lg-8 soep__text-soep">Tomatensoep gemixt</div>
                </div>
            </div>
            <div class="col-lg-1"></div>
        </div> -->
    <!-- </div> -->
</div>