import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Broodjes } from '../models/broodjes.model';

@Component({
  selector: 'app-broodjes',
  templateUrl: './broodjes.component.html',
  styleUrls: ['./broodjes.component.scss']
})
export class BroodjesComponent implements OnInit {
  public andereList: Broodjes[] = [];
  public veggyList: Broodjes[] = [];
  public selfMadeList: Broodjes[] = [];
  public kaasList: Broodjes[] = [];

  constructor(private http: HttpClient) {

    this.readCsvData();
  }

  ngOnInit(): void {
  }

  readCsvData() {
    this.http.get('assets/files/Prijslijsten.csv', { responseType: 'text' })
      .subscribe(
        data => {
          let csvToRowArray = data.split("\n");
          for (let index = 1; index < csvToRowArray.length - 1; index++) {
            let row = csvToRowArray[index].split(";");
            if (row[2] != "0") {
              if (row[1] == "Veggy") {
                this.veggyList.push(new Broodjes(row[0], row[1], row[2], row[3], row[4], row[5], row[6], row[7], row[8]));
              } else {
                if (row[0] == "Huisbereid") {
                  this.selfMadeList.push(new Broodjes(row[0], row[1], row[2], row[3], row[4], row[5], row[6], row[7], row[8]));
                }
                if (row[0] == "Andere") {
                  this.andereList.push(new Broodjes(row[0], row[1], row[2], row[3], row[4], row[5], row[6], row[7], row[8]));
                }
                if (row[0] == "Kaas") {
                  this.kaasList.push(new Broodjes(row[0], row[1], row[2], row[3], row[4], row[5], row[6], row[7], row[8]));
                }
              }
            }
          }
            // console.log(this.broodjesList);
          },
          error => {
            console.log(error);
          }
      );
  }

}
