<!-- <main class="container-fluid" style="height: 75vh"> -->
<main>
    <!-- <router-outlet></router-outlet> -->

    <app-home></app-home>
    <app-navigation></app-navigation>
    <app-about></app-about>
    <app-fotobroodje></app-fotobroodje>
    <app-broodjes></app-broodjes>
    <app-fotoschotels></app-fotoschotels>
    <app-schotels></app-schotels>
    <!-- <app-fotosoep></app-fotosoep> -->
    <app-soep></app-soep>
    <app-bestel></app-bestel>
    <app-contact></app-contact>
</main>