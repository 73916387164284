<div class="navigation">
    <input type="checkbox" class="navigation__checkbox" id="navi-toggle">

    <label for="navi-toggle" class="navigation__button">
        <span class="navigation__icon">&nbsp;</span>
    </label>
    <div class="navigation__background">&nbsp;</div>

    <nav class="navigation__nav">
        <ul class="navigation__list">
            <li id="nav-home" class="navigation__item">
                <a href="#" class="navigation__link">Home</a>
            </li>
            <li id="nav-about" class="navigation__item">
                <a href="#about" class="navigation__link">Over ons</a>
            </li>
            <li id="nav-broodjes" class="navigation__item">
                <a href="#broodjes" class="navigation__link">Broodjes</a>
            </li>
            <li id="nav-schotels" class="navigation__item">
                <a href="#schotels" class="navigation__link">Schotels</a>
            </li>
            <li id="nav-soep" class="navigation__item">
                <a href="#soep" class="navigation__link">Soep</a>
            </li>
            <li id="nav-bestel" class="navigation__item">
                <a href="#bestel" class="navigation__link">Bestellen</a>
            </li>
            <li id="nav-contact" class="navigation__item">
                <a href="#contact" class="navigation__link">Contact</a>
            </li>
            <!-- <li id="nav-icons" class="navigation__item-icon">
                <a class="navigation__link-icon" href="https://www.linkedin.com/in/kenny-wouters/" target="_blank"
                    title="LinkedIn Page">
                    <span class="navigation__link-icon-span">
                        <i class="fab fa-linkedin fa-2x"></i>
                    </span>
                </a>
                <a class="navigation__link-icon" href="./files/KennyWoutersCV.pdf" target="_blank"
                    title="Download CV">
                    <span class="navigation__link-icon-span">
                        <i class="fas fa-file-pdf fa-2x"></i>
                    </span>
                </a>
            </li> -->
        </ul>
    </nav>
</div>


<!-- <nav class="navi" id="navbar">
        <div class="navi__list">
            <a class="navi__item navi__item-1" href="#">Home</a>
            <a class="navi__item navi__item-2" href="#about">Over ons</a>
            <a class="navi__item navi__item-3" href="#broodjes">Broodjes</a>
            <a class="navi__item navi__item-4" href="#schotels">Schotels</a>
            <a class="navi__item navi__item-5" href="#soep">Soep</a>
            <a class="navi__item navi__item-6" href="#bestel">Bestellen</a>
            <a class="navi__item navi__item-7" href="#contact">Contact</a>
        </div>
</nav> -->