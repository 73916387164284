import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { BroodjesComponent } from './broodjes/broodjes.component';
import { SchotelsComponent } from './schotels/schotels.component';

const routes: Routes = [];
//   {
//     path: 'home',
//     component: HomeComponent
//   },
//   {
//     path: 'broodjes',
//     component: BroodjesComponent
//   },
//   {
//     path: 'schotels',
//     component: SchotelsComponent
//   },
//   {
//     path: '',
//     redirectTo: 'home',
//     pathMatch: 'full'
//   },
// ];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
