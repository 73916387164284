import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-schotels',
  templateUrl: './schotels.component.html',
  styleUrls: ['./schotels.component.scss']
})
export class SchotelsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
