export class Broodjes {
    type: string = "";
    veggy: string = "";
    beleg: string = "";
    price1: string = "";
    price2: string = "";
    price3: string = "";
    price4: string = "";
    price5: string = "";
    price6: string = "";
    
    constructor(type: string, veggy: string, beleg: string, price1: string, price2: string, price3: string, price4: string, price5: string, price6: string){
        this.type = type;
        this.veggy = veggy;
        this.beleg = beleg;
        this.price1 = price1;
        this.price2 = price2;
        this.price3 = price3;
        this.price4 = price4;
        this.price5 = price5;
        this.price6 = price6;
    }
}
