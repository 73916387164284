import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fotosoep',
  templateUrl: './fotosoep.component.html',
  styleUrls: ['./fotosoep.component.scss']
})
export class FotosoepComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
